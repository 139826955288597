import { jwtDecode } from "jwt-decode";
import { UserData, UserProfileResponseData } from "user/business/User";
import { userThunks } from "user/presentation/store/userSlice";
import moment from "moment";
import ApiService from "../services/ApiService";
import { KeyValuePair } from "fleet/presentation/components/VehicleCategory/ListVehicleCategory";

export const validateCitizenshipNumber = (_: any, value: any) => {
  const regex = /^[0-9]+([-\/][0-9]+)*$/;
  if (!value || regex.test(value)) {
    return Promise.resolve(); // Valid input
  }
  return Promise.reject(new Error("Invalid format!"));
};

export const hiddenMobileNumber = (phoneNumber: string): string => {
  if (typeof phoneNumber !== "string") {
    return "";
  }
  const visibleDigits = 4;
  if (phoneNumber.length <= visibleDigits) {
    return phoneNumber;
  }

  const visiblePart = phoneNumber.slice(0, 2);
  const hiddenPart = "*".repeat(phoneNumber.length - visibleDigits);
  const lastVisiblePart = phoneNumber.slice(-2);
  const formattedNumber: string = `${visiblePart}${hiddenPart}${lastVisiblePart}`;

  return formattedNumber;
};

export const isTimeBeforeCurrent = (givenTime: any) => {
  // Get the current date and time
  const now = new Date();

  // Parse the given time (format: "hh:mm AM/PM")
  const [time, modifier] = givenTime.split(" ");
  let [hours, minutes] = time.split(":").map(Number);

  // Convert hours from 12-hour format to 24-hour format
  if (modifier === "PM" && hours < 12) {
    hours += 12;
  } else if (modifier === "AM" && hours === 12) {
    hours = 0;
  }

  // Create a Date object for the given time
  const givenDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    hours,
    minutes
  );

  // Compare the given time with the current time
  if (givenDate < now) {
    return `Error: The given time ${givenTime} is before the current time.`;
  }
  return "";
};

export const getCurrentDate = (): String => {
  const currentDate = new Date();
  const options: any = {
    day: "numeric",
    month: "long",
    year: "numeric",
    timeZone: "Asia/Kathmandu",
  };

  const formattedDate = currentDate.toLocaleDateString("en-US", options);

  return formattedDate;
};

export const validateMobileNumber = (_: any, value: string): Promise<void> => {
  const mobileNumberRegex = /^[0-9]{10}$/;
  if (!value || mobileNumberRegex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject("Invalid mobile number");
};

export const validateMobileNumberWithChar = (
  _: any,
  value: string
): Promise<void> => {
  const mobileNumberRegex = /^(\+9779\d{9}|9\d{9})$/;

  const cleanedValue = value.replace(/\s/g, "");

  if (!cleanedValue) {
    return Promise.resolve();
  }

  if (mobileNumberRegex.test(cleanedValue)) {
    return Promise.resolve();
  }

  return Promise.reject("Invalid mobile number");
};

export const checkUniqueVehicleOwnerMobileNumber = async (
  _: any,
  value: string
): Promise<void> => {
  if (value && value.length === 10) {
    try {
      const response: any = await ApiService.setPost(
        `${process.env.REACT_APP_API_BASE_URL}/users/validate-contact`,
        {
          contactNumber: value,
        }
      );
      if (!response?.data?.success) {
        return Promise.reject(
          response?.data?.error?.contactNumber ??
            "Mobile number already exists."
        );
      }
      return Promise.resolve();
    } catch (error: any) {
      // setEmailError(error.error.email);
      return Promise.reject("Invalid mobile number");
    }
  } else {
    return Promise.resolve();
  }
};

export const validateSingleWord = (
  _: any,
  value: string,
  errorMessage: string
): Promise<void> => {
  const whitespaceRegex = /\s/;
  if (!whitespaceRegex.test(value)) {
    return Promise.resolve();
  }

  return Promise.resolve();
};

export const validateSingleWordWithoutNumber = (
  _: any,
  value: string,
  errorMessage: string
): Promise<void> => {
  const singleWordPattern = /^[A-Za-z]+$/;

  if (value === undefined || value === null || value.trim() === "") {
    return Promise.resolve();
  }

  if (!singleWordPattern.test(value)) {
    return Promise.reject(new Error(errorMessage));
  }

  return Promise.resolve();
};

export const transformContactData = (contacts: any[]) => {
  return contacts?.map((contact, index) => ({
    key: `contact_${contact.contactPersonId}`,
    contactPersonName: contact.name,
    contactPersonNumber: contact.contactNumber,
    contactPersonDesignation: contact.designation,
    contactPersonEmail: contact.email,
  }));
};

export const transformAlternativeContactData = (alternativeContacts: any[]) => {
  return alternativeContacts?.map((Acontact, index) => ({
    key: index,
    alternativeContact: Acontact,
  }));
};

export const splitFullNameIntoParts = (
  fullName: string
): { firstName: string; middleName: string; lastName: string } => {
  const parts = fullName.trim().split(/\s+/);
  let firstName = "";
  let middleName = "";
  let lastName = "";

  if (parts.length === 1) {
    firstName = parts[0];
  } else if (parts.length === 2) {
    firstName = parts[0];
    lastName = parts[1];
  } else if (parts.length > 2) {
    firstName = parts[0];
    middleName = parts.slice(1, -1).join(" ");
    lastName = parts[parts.length - 1];
  }

  return { firstName, middleName, lastName };
};

export const validateAlternativeContact = (
  _: any,
  value: string
): Promise<void> => {
  const mobileNumberRegex = /^[0-9]{10}$/;
  const landlineNumberRegex = /^0[0-9]{8}$/;

  if (
    !value ||
    mobileNumberRegex.test(value) ||
    landlineNumberRegex.test(value)
  ) {
    return Promise.resolve();
  }

  return Promise.reject("Invalid alternative contact");
};

export const validateEmail = (_: any, value: string): Promise<void> => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!value || emailRegex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject("Invalid email address");
};

export const validateLastName = (_: any, name: string): Promise<void> => {
  const regex = /^[A-Za-z]+$/;
  if (!name || regex.test(name)) {
    return Promise.resolve();
  }
  return Promise.reject("Invalid Last Name");
};

export const validateFirstName = (_: any, name: string): Promise<void> => {
  const regex = /^[A-Za-z]+$/;
  if (!name || regex.test(name)) {
    return Promise.resolve();
  }
  return Promise.reject("Invalid First Name");
};

export const dateFormatter = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    second: "numeric",
  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const validateMiddleName = (_: any, name: string): Promise<void> => {
  const regex = /^[A-Za-z- ]+$/;
  if (!name || regex.test(name)) {
    return Promise.resolve();
  }
  return Promise.reject("Invalid Middle Name");
};

export const validateNumberWithChar = (_: any, name: string): Promise<void> => {
  const regex = /^[0-9 !@#$%^&*()_+={}\[\]|;:'",.<>?`~\-\\]+$/;

  if (!name || regex.test(name)) {
    return Promise.resolve();
  }
  return Promise.reject("Invalid Middle Name");
};

export const validateUsername = (rule: any, value: string) => {
  const isEmail = validateEmail(null, value)
    .then(() => true)
    .catch(() => false);
  const isMobileNumber = validateMobileNumber(null, value)
    .then(() => true)
    .catch(() => false);

  return Promise.all([isEmail, isMobileNumber]).then(
    ([emailIsValid, mobileNumberIsValid]) => {
      if (emailIsValid || mobileNumberIsValid) {
        return Promise.resolve();
      } else {
        return Promise.reject("Please enter a valid email or contact number");
      }
    }
  );
};

export const getTodayDate = () => {
  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();

  return `${year}-${month}-${day}`;
};

export const getCurrentUserDetails = async (dispatch: any) => {
  try {
    const response = await dispatch(userThunks.getUserProfileThunk());
    const dataResponse = response.payload.data as UserProfileResponseData;
    return dataResponse;
  } catch (error) {
    console.error("Error fetching user details:", error);
    throw error;
  }
};

export const disableFutureDates = (current: any) => {
  if (current) {
    const today = moment().endOf("day");
    return current.isAfter(today);
  }
  return false;
};

export const disablePastDates = (current: any) => {
  if (current) {
    const today = moment().startOf("day");
    return current.isBefore(today, "day");
  }
  return false;
};

export const getShipperDetails = async (dispatch: any) => {
  try {
    const response = await userThunks.getShipperThunk();
    const dataResponse = response.data;
    return dataResponse;
  } catch (error) {
    console.error("Error fetching user details:", error);
    throw error;
  }
};

export const isNumber = (_: any, value: string, callback: Function) => {
  if (value && isNaN(value as any)) {
    callback("Please enter a valid number");
  } else {
    callback();
  }
};

export const validateSpecialNumericalCharacters = (
  _: any,
  value: string,
  callback: Function
) => {
  // Allow empty input
  if (!value) {
    callback();
    return;
  }
  const isValid = /^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;"'<>,.?/\\|`~\-=\s]*$/;

  if (!isValid.test(value)) {
    callback("Please enter a valid alphanumeric or special character string");
  } else {
    callback();
  }
};

export const isText = (_: any, value: string, callback: Function) => {
  const textOnly = /^[A-Za-z\s]+$/;
  if (value && !textOnly.test(value)) {
    callback("Please enter valid text");
  } else {
    callback();
  }
};

export const isTextWithChar = (_: any, value: string, callback: Function) => {
  const textWithSpecialChars = /^[A-Za-z\s!@#$%^&*(),.?":{}|<>~`'/-]+$/;
  if (value && !textWithSpecialChars.test(value)) {
    callback("Please enter valid text");
  } else {
    callback();
  }
};

export const validateRegistrationNumber = (
  _: any,
  value: string,
  callback: Function
) => {
  const regExp = /^[0-9]+([-\/][0-9]+)*$/;
  if (value && !regExp.test(value)) {
    callback("Please enter valid registration number");
  } else {
    callback();
  }
};

export const validatePANNumber = (
  _: any,
  value: string,
  callback: Function
) => {
  if (value) {
    const isNumeric = /^[0-9]*$/;
    if (!isNumeric.test(value)) {
      callback("Please enter a valid PAN Number consisting of 9 digits.");
    } else if (value.length !== 9) {
      callback("Please enter a PAN Number with exactly 9 digits.");
    } else {
      callback();
    }
  } else {
    callback();
  }
};

export const validateLicenseNumber = (rule: any, value: any) => {
  const pattern = /^[0-9]+(-[0-9]+)*$/;
  if (!pattern.test(value)) {
    return Promise.reject("Invalid license number");
  }
  return Promise.resolve();
};

export const validateUserType = (
  _: any,
  value: string | undefined,
  callback: Function
) => {
  if (!value) {
    callback("Please select a user type");
  } else {
    callback();
  }
};

export const validatePassword = (
  rule: any,
  value: string,
  callback: Function
) => {
  const passwordRegex =
    /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;

  if (!passwordRegex.test(value)) {
    callback(
      "Your password must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character."
    );
  } else {
    callback();
  }
};

/**
 * @param firstName - The first name of the person.
 * @param lastName - The last name of the person.
 * @param middleName - (Optional) Provide the middle name as the third argument if applicable.
 * @returns The formatted full name as a string.
 */
export const getFormattedFullName = (
  firstName: string,
  lastName: string,
  middleName?: string
): string => {
  if (middleName) {
    return `${firstName} ${middleName} ${lastName}`;
  }
  return `${firstName} ${lastName}`;
};

export const hasPermission = (userPermissions: any, permission: any) => {
  const { user } = userPermissions || "";
  let permissions: Array<string> = user?.permissions;
  if (permissions) {
    if (permission?.includes("*")) {
      return true;
    } else {
      return permission?.some((p: string) => permissions.includes(p));
    }
  }
};

export function convertDateFormat(dateString: any) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function convertTimeFormat(timeString: any) {
  const date = new Date(timeString);
  const hours = String(date.getHours() % 12 || 12).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const period = date.getHours() < 12 ? "AM" : "PM";
  return `${hours}:${minutes} ${period}`;
}

export function convertInitialTimeFormat(timeString: any) {
  if (typeof timeString === "string") {
    const timeParts = timeString.split(":");
    const hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);
    const seconds = parseInt(timeParts[2]);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    const period = date.getHours() < 12 ? "AM" : "PM";
    const formattedHours = date.getHours() % 12 || 12;
    return `${formattedHours}:${String(minutes).padStart(2, "0")} ${period}`;
  }
  // Handle other cases if needed
  return ""; // or throw an error
}

export const formatDate = (dateString: string | number | Date) => {
  if (dateString) {
    // Parse the date in UTC context
    const date = new Date(dateString);

    // Use UTC methods to prevent local time zone adjustments
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");

    return `${year}/${month}/${day}`;
  }
  return null;
};

export const formateDateDash = (dateString: string | number | Date) => {
  if (dateString) {
    // Parse the date in UTC context
    const date = new Date(dateString);

    // Use UTC methods to prevent local time zone adjustments
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  return null;
};

export const formatTime = (timeString: string) => {
  const time = new Date(`1970-01-01T${timeString}`);
  let hours = time.getHours();
  const minutes = String(time.getMinutes()).padStart(2, "0");
  const meridiem = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12;

  return `${hours}:${minutes} ${meridiem}`;
};

export function calculateDistance(pickup: any, delivery: any) {
  const earthRadius = 6371;
  const toRadians = (degrees: any) => degrees * (Math.PI / 180);

  const dLat = toRadians(delivery.lat - pickup.lat);
  const dLng = toRadians(delivery.lng - pickup.lng);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(pickup.lat)) *
      Math.cos(toRadians(delivery.lat)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = earthRadius * c;
  return distance;
}

export const getRoleCode = (): string | null => {
  const idToken = localStorage.getItem("idToken");
  if (!idToken) return null;

  try {
    const decodedToken: any = jwtDecode(idToken);
    return decodedToken?.user?.roles[0]?.roleCode || null;
  } catch (error) {
    console.error("Failed to decode token", error);
    return null;
  }
};

export function validateText(text: any): string | undefined {
  if (!/^[a-zA-Z]+$/.test(text)) {
    return "Please type text only";
  } else {
    return "";
  }
}

export function deepEqual(arr1: any[], arr2: any[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
      return false;
    }
  }
  return true;
}

export const formatInitialDate = (dateString: string | number | Date) => {
  if (dateString) {
    return moment(dateString).format("YYYY-MM-DD");
  }
  return "";
};
export const dateFormat = "YYYY/MM/DD";

export const validateZonalNumber = (
  vehicleNumber: string | null | any
): boolean => {
  return (
    !!vehicleNumber &&
    /^[A-Z]{2,3}-[0-9]{2}-[A-Z]{2,3}-[0-9]{4}$/.test(vehicleNumber)
  );
};

export const validateProvinceNumber = (
  vehicleNumber: string | null | any
): any => {
  return (
    !!vehicleNumber &&
    /^Province-[0-9]{2}-[0-9]{2}-[0-9]{3}-[A-Z]{2,3}-[0-9]{4}$/.test(
      vehicleNumber
    )
  );
};
export const validateEmbossedNumber = (
  vehicleNumber: string | null | any
): boolean => {
  return (
    !!vehicleNumber &&
    /^State-[0-9]{2}-[A-Z0-9]{1,2}-[A-Z]{2}-[0-9]{4}$/.test(vehicleNumber)
  );
};

export const getRemainingDays = (expiryDateStr: string): number | false => {
  const expiryDate = new Date(expiryDateStr);
  const currentDate = new Date();
  const timeDiff = expiryDate.getTime() - currentDate.getTime();

  if (timeDiff < 0) {
    return false;
  }

  const daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  return daysRemaining;
};

export const formatPlateNumber = (plateType: string, formData: any) => {
  if (plateType === "ZONAL") {
    return `${formData?.zonalCode}-${formData?.lotNumber
      .toString()
      .padStart(2, 0)}-${formData?.symbol}-${formData?.number
      .toString()
      .padStart(4, 0)}`;
  }

  if (plateType === "PROVINCIAL") {
    return `${formData?.province}-${formData?.registeredOffice}-${formData?.lotNumber}-${formData?.symbol}-${formData?.number}`;
  }

  if (plateType === "EMBOSSED") {
    return `${formData?.state}-${formData?.vehicleTypeInPlate}-${formData?.embossedVAIC}-${formData?.number}`;
  }

  return "";
};

export const VEHICLE_STATUS: any[] = [
  { status: "JOURNEY_STARTED" },
  { status: "EN_ROUTE" },
  { status: "ON_HOLD" },
  { status: "RESUMPTION_JOURNEY" },
  { status: "JOURNEY_END" },
  { status: "OFF_DUTY" },
  { status: "PARK" },
  { status: "DELAY" },
  { status: "MAINTENANCE" },
  { status: "IN_QUEUE" },
  { status: "RESERVED" },
];

export const generateKeyValuePairsForVehicleStatus = (): {
  key: number;
  value: string;
}[] => {
  return VEHICLE_STATUS.map((item, index) => ({
    key: index,
    value: item.status,
  }));
};

export const vehiclePlateColor: any[] = [
  { id: 0, value: "Green", key: "green" },
  { id: 1, value: "White", key: "white" },
  { id: 2, value: "Red", key: "red" },
  { id: 3, value: "Blue", key: "blue" },
  { id: 4, value: "Green", key: "green" },
  { id: 5, value: "Black", key: "black" },
  { id: 6, value: "Yellow", key: "yellow" },
];

export const vehiclePlateType: any[] = [
  { id: 0, value: "Zonal", key: "zonal" },
  { id: 1, value: "Provincial", key: "provincial" },
  { id: 2, value: "Embossed", key: "embossed" },
];

export const vehiclePendingStatus: any[] = [
  { id: 0, value: "Pending", key: "pending" },
  { id: 1, value: "Edit-request", key: "edit-request" },
];

export const generateTripStatusKeyValuePairs = (): KeyValuePair[] | any => {
  return [
    {
      id: 6,
      title: "Created",
      key: "created",
    },
    {
      id: 0,
      value: "Journey Started",
      key: "journey_started",
    },
    {
      id: 3,
      value: "En Route",
      key: "en_route",
    },
    {
      id: 1,
      value: "On Hold",
      key: "on_hold",
    },
    {
      id: 4,
      value: "Resume Journey",
      key: "resume_journey",
    },
    {
      id: 2,
      value: "Completed",
      key: "completed",
    },
  ];
};

export const isPastDeliveryDate = (deliveryDate: string) => {
  const currentDate = new Date();
  const deliveryDateObj = new Date(deliveryDate);
  return deliveryDateObj < currentDate;
};

export const trimWhiteSpace = (_: any, name: string): Promise<void> => {
  if (name.startsWith(" " || name.endsWith(" "))) {
    return Promise.reject("Space not allowed!");
  }
  return Promise.resolve();
};

export const getWeightInKG = (
  value: number,
  currentUnit: string,
  adjustment: number
) => {
  if (currentUnit === "Ton" || currentUnit === "Metric Ton") {
    return value * 1000 * adjustment;
  } else if (currentUnit === "Quintal") {
    return value * 100 * adjustment;
  } else return value;
};

export const getUnitAdjustment = (
  shipmentUnit: string,
  vehicleTypeUnit: string
) => {
  if (shipmentUnit === "Metric Ton") {
    shipmentUnit = "Ton";
  }
  if (vehicleTypeUnit === "Metric Ton") {
    vehicleTypeUnit = "Ton";
  }

  if (vehicleTypeUnit === shipmentUnit) {
    if (vehicleTypeUnit === "Ton") {
      return 1;
    } else {
      return 1;
    }
  } else {
    if (shipmentUnit === "Ton" && vehicleTypeUnit === "Quintal") {
      return 0.1;
    }
    if (shipmentUnit === "Quintal" && vehicleTypeUnit === "Ton") {
      return 10;
    } else {
      return 1;
    }
  }
};

export const getWeightInParticularUnit = (value: number, unitName: string) => {
  if (unitName === "Ton" || unitName === "Metric Ton") {
    return value / 1000;
  } else if (unitName === "Quintal") {
    return value / 100;
  } else return value;
};

export const getUniqueObjects = (array: any) => {
  const uniqueMap = new Map(); // Initialize a Map

  array
    ?.filter(
      (value: any) =>
        value?.value !== undefined &&
        value?.value !== null &&
        value?.value !== ""
    )
    ?.forEach((obj: any) => {
      // Iterate Over the Array
      const key = `${obj.name}-${obj.value}`; // Generate a Unique Key
      if (!uniqueMap.has(key)) {
        // Check and Add to Map
        uniqueMap.set(key, obj); // Adding the object to the map
      }
    });

  return Array.from(uniqueMap.values()); // Convert Map Values to Array
};

export const isTripRunning = (vehicle: any) => {
  return (
    vehicle?.tripId &&
    ["journey_started", "on_hold", "en_route", "resume_journey"].includes(
      vehicle?.tripStatus
    )
  );
};

export const calculateMapCenterAndZoom = (data: any) => {
  let minLat = Infinity;
  let maxLat = -Infinity;
  let minLng = Infinity;
  let maxLng = -Infinity;

  const MIN_ZOOM_LEVEL = 5;
  const MAX_ZOOM_LEVEL = 18;

  data?.forEach((marker: any) => {
    minLat = Math.min(minLat, marker?.latitude);
    maxLat = Math.max(maxLat, marker?.latitude);
    minLng = Math.min(minLng, marker?.longitude);
    maxLng = Math.max(maxLng, marker?.longitude);
  });

  const centerLat = (minLat + maxLat) / 2;
  const centerLng = (minLng + maxLng) / 2;

  const latDistance = maxLat - minLat;
  const lngDistance = maxLng - minLng;
  const maxDistance = Math.max(latDistance, lngDistance);

  const calculatedZoom = Math.round(
    Math.log(360 / (maxDistance * 2)) / Math.LN2
  );
  const adjustedZoom = Math.min(
    MAX_ZOOM_LEVEL,
    Math.max(MIN_ZOOM_LEVEL, calculatedZoom)
  );

  return {
    center:
      data?.length > 0
        ? { lat: centerLat, lng: centerLng }
        : { lat: 27.692087, lng: 85.304582 },
    zoom: data?.length > 0 ? adjustedZoom : 10,
  };
};
export const ProvinceOrder = [
  "Koshi",
  "Madhesh",
  "Bagmati",
  "Gandaki",
  "Lumbini",
  "Karnali",
  "Sudur Pashchim",
];
