import React, { lazy } from "react";
import { PublicRoute } from "../../../core/presentation/navigation/types/route";

const orderRoutes = [
  {
    path: "/order-cancel",
    Component: lazy(() => import("../pages/Bids/CancelledOrders")),
    permission: ["re_bid:list_all"],
  },
  {
    path: "/orders",
    Component: lazy(() => import("../pages/OrderProcessing")),
    permission: [
      "order:list",
      "order:list_all",
      "bid_request:list_all",
      "bid_request:list",
    ],
  },
  {
    path: "/orders/create",
    Component: lazy(
      () => import("../components/createPageI/OrderProcessingMain")
    ),
    permission: ["order:create"],
  },
  {
    path: "/orders/details/:id",
    Component: lazy(
      () => import("../components/BIds/BidHistoryViewPostPayment")
    ),
    permission: ["bid_request:detail", "order:detail"],
  },
  {
    path: "*",
    Component: lazy(() => import("../../../core/presentation/pages/NotFound")),
    permission: ["*"],
  },
  {
    path: "/orders/bid-requests",
    Component: lazy(() => import("../pages/Bids")),
    permission: ["bod_request:list_all"],
  },
  {
    path: "/orders/bid-requests/:id",
    Component: lazy(() => import("../pages/Bids/Bids")),
    permission: ["bid_request:detail"],
  },
  {
    path: "/orders/history/:id",
    Component: lazy(() => import("../components/BIds/BidHistoryView")),
    permission: ["bid_request:detail", "order:detail"],
  },
  {
    path: "/orders/bid-details/:id",
    Component: lazy(
      () =>
        import(
          "../../../fleet/presentation/components/OrderAndBids/OrderDetails"
        )
    ),
    permission: ["bid_request:detail"],
  },
  {
    path: "/orders/details/:id",
    Component: lazy(
      () => import("../components/BIds/BidHistoryViewPostPayment")
    ),
    permission: ["bid_request:detail", "order:detail"],
  },
  {
    path: "/order",
    Component: lazy(() => import("../pages/Order/index")),
    permission: ["bid_request:detail", "order:list_all", "order:list"],
  },
  {
    path: "/order/details/:orderType/:orderId",
    Component: lazy(() => import("../pages/Order/OrderDetails")),
    permission: ["bid_request:detail", "order:detail"],
  },
];

export default orderRoutes;
